<template>
  <div>
    <v-card flat>
      <div ref="chart"></div>
    </v-card>
  </div>
</template>

<script>
import ApexCharts from 'apexcharts';

export default {
  name: "CuotasStatusGraphic",
  props: {
    cuotas: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      chart: null
    };
  },
  mounted() {
    this.renderChart();
  },
  watch: {
    cuotas: {
      deep: true,
      handler() {
        if (this.chart) {
          const keys = Object.keys(this.cuotas);
          const series = keys.map(key => this.cuotas[key] || 0);
          // Actualizamos los valores y las etiquetas del gráfico
          this.chart.updateOptions({
            series: series,
            labels: keys
          });
        }
      }
    }
  },
  methods: {
    renderChart() {
      if (!this.cuotas) return;
      
      const keys = Object.keys(this.cuotas);
      const series = keys.map(key => this.cuotas[key] || 0);
      
      const options = {
        series: series,
        chart: {
          height: 350,
          type: 'radialBar',
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350
            }
          }
        },
        plotOptions: {
          radialBar: {
            offsetY: 0,
            startAngle: 0,
            endAngle: 350,
            hollow: {
              margin: 5,
              size: '30%',
              background: 'transparent'
            },
            track: {
              show: true,
              background: '#f2f2f2',
              opacity: 0.5,
              strokeWidth: '95%',
              margin: 5,
              dropShadow: {
                enabled: false
              }
            },
            dataLabels: {
              name: { show: false },
              value: { show: false },
              total: {
                show: true,
                label: 'Total',
                fontSize: '14px',
                formatter: function (w) {
                  return w.globals.seriesTotals.reduce((acc, val) => acc + val, 0);
                }
              }
            },
            cornerRadius: '55%',
            strokeLinecap: 'round',
            trackSize: '95%',
            barSize: '85%',
            distributed: false
          }
        },
        stroke: {
          lineCap: 'round',
          width: 4
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: undefined,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100]
          }
        },
        labels: keys,
        legend: {
          show: true,
          floating: true,
          position: 'right',
          horizontalAlign: 'center',
          offsetY: 50,
          fontSize: '14px',
          labels: {
            useSeriesColors: true
          },
          markers: {
            size: 8,
            radius: 12
          },
          formatter: function(seriesName) {
            return seriesName;
          },
          itemMargin: {
            vertical: 10
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              show: true,
              position: 'bottom'
            }
          }
        }]
      };

      this.chart = new ApexCharts(this.$refs.chart, options);
      this.chart.render();
    }
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  }
};
</script>

<style scoped>
:deep(.apexcharts-radialbar-track) path {
  stroke-linecap: round;
}

:deep(.apexcharts-radialbar) path {
  stroke-linecap: round;
}
</style>
